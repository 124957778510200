import { getUrl } from 'utils/api'

export function excerpt(string, length = 60) {
    if (!string) return ''

    if (string.length <= length) {
        return string
    }
    return `${string.substring(0, length)}...`
}

export function parseFormEvent(e) {
    if (typeof e.name !== 'undefined' && typeof e.value !== 'undefined') {
        return e
    }

    let name, value
    const target = e.target
    const type = target.getAttribute('type')

    name = target.name
    if (type === 'file') {
        value = target.files[0]
    } else if (type === 'checkbox') {
        value = target.checked ? 1 : 0
    } else {
        value = target.value
    }

    return { name, value }
}

// page links
export function getRoute(type, object) {
    switch (type) {
        // audience
        case 'audience':
            return `/dovanos/${object.slug}`
        // blog
        case 'buyerblog':
            return '/tinklarastis-pirkejams'
        case 'blog':
            return '/tinklarastis'
        case 'blog-category':
            if (object.buyers) {
                return `/tinklarastis-pirkejams/${object.slug}`
            }
            return `/tinklarastis/${object.slug}`
        case 'blog-article':
            if (object.buyers) {
                return `/tinklarastis-pirkejams/straipsnis/${object.slug}`
            }
            return `/tinklarastis/straipsnis/${object.slug}`
        // account
        case '[account]':
        case 'account-dashboard':
            return '/paskyra'
        case 'account-updates':
            return '/paskyra/naujienos'
        case 'account-new-product':
            return '/paskyra/prekes/nauja'
        case 'account-products':
            return '/paskyra/prekes'
        case 'account-product':
            return `/paskyra/prekes/${object.code}`
        case 'account-settings':
            return '/paskyra/nustatymai'
        case 'account-purchases':
            return '/paskyra/uzsakymai'
        case 'account-invoices':
            return '/paskyra/saskaitos'
        case 'account-contract':
            return '/paskyra/sutartis'
        case 'account-deals':
            return '/paskyra/pasiulymai'
        // checkout
        case '[checkout]':
            return '/pirkimas'
        case 'checkout-cart':
            return '/pirkimas/krepselis'
        case 'checkout-info':
            return '/pirkimas/informacija'
        case 'checkout-delivery':
            return '/pirkimas/pristatymas'
        case 'checkout-payment':
            return '/pirkimas/apmokejimas'
        case 'checkout-success':
            return '/uzsakymas-priimtas'
        // profile
        case '[profile]':
        case 'profile-details':
            return '/profilis'
        case 'change-password':
            return '/profilis/slaptazodis'
        case 'profile-purchases':
            return '/profilis/uzsakymai'
        // static
        case 'safe-shopping':
            return '/saugus-apsipirkimas'
        case 'purchase-sale-rules':
            return '/pirkimo-pardavimo-taisykles'
        case 'privacy-policy':
            return '/privatumo-politika'
        case 'cookie-policy':
            return '/slapuku-politika'
        case 'return':
            return '/grazinimas'
        case 'delivery':
            return '/pristatymas'
        case 'faq':
            return '/duk'
        case 'data-processing-contract':
            return '/duomenu-tvarkymo-sutartis'
        // pages
        case 'forgot-password':
            return '/slaptazodzio-atkurimas'
        case 'registration':
            return '/registracija'
        case 'login':
            return '/prisijungimas'
        case 'shops':
            return '/parduotuves'
        case 'shop':
            return `/parduotuves/${object.slug}`
        case 'taxonomy':
            return `/k/${object.path}`
        case 'themes':
            return '/temos'
        case 'theme':
            return `/temos/${object.slug}`
        case 'features':
            return '/isskirtinumai'
        case 'feature':
            return `/isskirtinumai/${object.slug}`
        case 'brand':
            return `/parduotuves/${object.account_slug}?b=${object.brand_slug}`
        case 'landing':
            return '/pradekite-prekyba'
        case 'search':
            return '/paieska'
        case 'suggestions':
            return '/pasiulymai'
        case 'loyalty-program':
            return '/biciuliu-klubas'
        case 'reviews':
            return '/atsiliepimai'
        case 'about':
            return '/apie'
        case 'group':
            return `/g/${object.slug}`
        case '[product]':
            return `/preke`
        case 'product-preview':
            if (object.published) {
                return `/preke/${object.slug}`
            }
            return `/preke/perziura?c=${object.code}`
        case 'product':
            return `/preke/${object.slug}`
        case '[purchase]':
            return '/uzsakymas'
        case 'purchase':
            return `/uzsakymas/${object.secret}`
        case 'onboarding':
            return `/registracija-prekybai`
        case 'favorited':
            return '/patikusios-prekes'
        case 'tag':
            return `/zymos/${object.slug}`
        // external
        case 'instructions':
            return 'https://zinynas.lietuviskapreke.lt/prekiu-kurimas/reikalavimai-ir-patvirtinimas'
        case 'offsite-ads':
            return 'https://zinynas.lietuviskapreke.lt/lesu-ismokejimas-ir-mokesciai#kada-taikomas-isorin-s-reklamos-mokestis'
        case 'seller-duk':
            return 'https://zinynas.lietuviskapreke.lt/uzsakymu-valdymas/duk'
        case 'facebook':
            return 'https://www.facebook.com/lietuviskapreke.lt'
        case 'instagram':
            return 'https://www.instagram.com/lietuviskapreke.lt/'
        case 'contact':
            return 'mailto:info@lietuviskapreke.lt'
        // files
        case 'invoice':
            return getUrl(`invoice/${object.secret}`)
        case 'seller-invoice':
            return getUrl(`seller-invoice/${object.id}`)
        case 'contract':
            return getUrl('contract')
        case 'contract-example':
            return getUrl('contract-example')
        case 'tax-example':
            return '/files/mokesciu-skaiciavimo-pavyzdziai.pdf'
    }
    return '/'
}

// marge classNames
export function cn() {
    return [...arguments].filter(e => !!e).join(' ')
}

// does page has minimal UI
export function getIsMinimal(pathname) {
    if (!pathname) {
        return false
    }

    return (
        pathname.startsWith(getRoute('[checkout]')) ||
        pathname.startsWith(getRoute('[purchase]')) ||
        pathname.startsWith(getRoute('[account]')) ||
        pathname.startsWith(getRoute('[profile]')) ||
        pathname.startsWith(getRoute('landing')) ||
        pathname.startsWith(getRoute('onboarding')) ||
        pathname == getRoute('login') ||
        pathname == getRoute('registration') ||
        pathname == getRoute('forgot-password')
    )
}

// group array of objects by property
export function groupBy(items, key) {
    return items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [...(result[item[key]] || []), item]
        }),
        {}
    )
}

// convert text to slug
export function slugify(str) {
    str = str.replace(/^\s+|\s+$/g, '')
    str = str.toLowerCase()

    var from = 'ąčėęšūžàáäâèéëêìíïîòóöôùúüûñç·/_,:;'
    var to = 'aceesuzaaaaeeeeiiiioooouuuunc------'
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    str = str
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')

    return str
}

// nl2br
export function nl2br(str) {
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2')
}

// checkout

export function getQuantityOptions(quantity) {
    if (quantity < 1) return []
    return [...Array(quantity).keys()].map(k => ({
        name: k + 1,
        value: k + 1
    }))
}

export function getCartAccounts(items, accInfo, couponCheck) {
    const groups = groupBy(items, 'account_name')
    return Object.keys(groups).map(name => {
        return {
            name,
            slug: groups[name][0].account_slug,
            vat_size: groups[name][0].vat_size,
            items: groups[name].map(item => {
                if (accInfo) {
                    const price = couponCheck({
                        account_discount: accInfo[item.account_slug],
                        account_slug: item.account_slug,
                        simple_price: item.simple_price,
                        price: item.data.price,
                        single_price: parseFloat(item.single_price)
                    }).price
                    item.data.price_fixed = price
                } else {
                    item.data.price_fixed = item.data.price
                }
                return item
            }),
            total: groups[name].reduce((a, b) => a + parseFloat(b.data.price_fixed) * parseInt(b.data.quantity), 0)
        }
    })
}

// price preview
export function getPricePreview(data) {
    const { price_from, price_to } = data
    if (price_from == price_to) {
        return parseFloat(price_from).toFixed(2) + '€'
    } else {
        return parseFloat(price_from).toFixed(2) + '€ - ' + parseFloat(price_to).toFixed(2) + '€'
    }
}

export function getCartId({ id, data }) {
    const { options } = data
    const optionsString = options
        ? Object.keys(options)
              .map(k => options[k])
              .join(', ')
        : ''
    return `${id}-${optionsString}`
}
