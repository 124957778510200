import Link from 'next/link'
import Svg from 'components/svg/svg'
import { cn } from 'utils/helpers'
import styles from './btn.module.scss'

export default function Btn({
    href,
    className,
    download,
    icon,
    children,
    block,
    onClick,
    external,
    ghost,
    size,
    type,
    style
}) {
    const classNames = cn(
        styles.btn,
        styles[style],
        block && styles.block,
        size && styles[size],
        ghost && styles.ghost,
        className
    )

    const svg = icon ? <Svg name={icon} /> : null

    const btnType = type || (onClick ? 'button' : 'submit')

    if (external) {
        return (
            <a
                href={href}
                rel="noreferrer noopener"
                target="_blank"
                className={classNames}
                download={download}
                onClick={onClick}
            >
                {svg}
                <span>{children}</span>
            </a>
        )
    }

    if (href) {
        return (
            <Link href={href}>
                <a className={classNames} onClick={onClick}>
                    {svg}
                    <span>{children}</span>
                </a>
            </Link>
        )
    }

    if (onClick) {
        return (
            <button type={btnType} onClick={onClick} className={classNames}>
                {svg}
                <span>{children}</span>
            </button>
        )
    }

    return (
        <button type={btnType} className={classNames}>
            {svg}
            <span>{children}</span>
        </button>
    )
}
