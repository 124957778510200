//https://github.com/huwcarwyn/react-context-modals
import dynamic from 'next/dynamic'
import React, { createContext, useReducer, useEffect, useContext } from 'react'

const Message = dynamic(() => import('components/modals/message'))
const Confirm = dynamic(() => import('components/modals/confirm'))
const ModalRoot = dynamic(() => import('components/modals/root'))

const ModalContext = createContext({
    component: () => '',
    modalProps: {},
    showModal: () => undefined,
    hideModal: () => undefined,
    showConfirm: () => undefined,
    showMessage: () => undefined
})

const { Provider, Consumer: ModalConsumer } = ModalContext

const reducer = (state, { type, component, modalProps }) => {
    switch (type) {
        case 'openModal':
            document.querySelector('html').classList.add('noScroll')
            return { ...state, component, modalProps }
        case 'hideModal':
            document.querySelector('html').classList.remove('noScroll')
            return { ...state, component: null, modalProps: {} }
        default:
            throw new Error('Unspecified reducer action')
    }
}

const ModalProvider = ({ children, animated, CloseComponent }) => {
    const initialState = {
        component: null,
        modalProps: {},
        showMessage: text => {
            const modalProps = { text }
            dispatch({ type: 'openModal', component: Message, modalProps })
        },
        showConfirm: async text => {
            return new Promise((resolve, reject) => {
                const modalProps = { text, resolve, reject }
                dispatch({ type: 'openModal', component: Confirm, modalProps })
            }).catch(() => {})
        },
        showModal: (component, modalProps = {}) => {
            dispatch({ type: 'openModal', component, modalProps })
        },
        hideModal: () => {
            dispatch({ type: 'hideModal' })
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const onKeyDown = e => {
        if (e.key === 'Escape') {
            state.hideModal()
        }
    }

    useEffect(() => {
        state.component === null
            ? document.addEventListener('keydown', onKeyDown)
            : document.removeEventListener('keydown', onKeyDown)
    }, [state.component])

    return (
        <Provider value={state}>
            <ModalRoot CloseComponent={CloseComponent} animated={animated} />
            {children}
        </Provider>
    )
}

const useModal = () => useContext(ModalContext)

export { ModalConsumer, ModalProvider, useModal }
