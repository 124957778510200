import Image from 'components/image/image'
import Heading from 'components/heading/heading'
import Btn from 'components/btn/btn'
import Input from 'components/input/input/input'
import styles from './modal.module.scss'

export default function NewsletterModal({ email, onChange, onSubmit }) {
    return (
        <div className={styles.newsletter}>
            <div className={styles.image}>
                <Image
                    src="/img/news.jpg"
                    alt="Gaukite nuolaidų iki 10% kodą į el. paštą!"
                    layout="responsive"
                    width="1904"
                    height="504"
                    objectFit="cover"
                />
            </div>
            <div className={styles.mobileImage}>
                <Image
                    src="/img/plan-2.png"
                    alt="Gaukite nuolaidų iki 10% kodą į el. paštą!"
                    layout="responsive"
                    width="135"
                    height="152"
                />
            </div>
            <div className={styles.content}>
                <div className={styles.text}>
                    <Heading tag="h2" size="30" className="mb12">
                        Gaukite nuolaidų iki 10% <br /> kodą į el. paštą!
                    </Heading>
                    <p>Siunčiame ne daugiau nei vieną laišką per savaitę.</p>
                </div>
                <form className={styles.group} onSubmit={onSubmit}>
                    <Input
                        simple
                        type="email"
                        name="email"
                        value={email}
                        required
                        className={styles.input}
                        onChange={onChange}
                        placeholder="El. pašto adresas"
                    />
                    <Btn style="red">Prenumeruoti</Btn>
                </form>
            </div>
        </div>
    )
}
