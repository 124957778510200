export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID

export const init = () => {
    gtag('js', new Date())
    gtag('config', GA_TRACKING_ID, {
        page_path: window.location.pathname
    })
}

export const revokeAd = () => {
    window.gtag('consent', 'update', {
        ad_storage: 'denied'
    })
}

export const revokeAnalytics = () => {
    window.gtag('consent', 'update', {
        analytics_storage: 'denied'
    })
}

export const grantAd = () => {
    window.gtag('consent', 'update', {
        ad_storage: 'granted'
    })
}

export const grantAnalytics = () => {
    window.gtag('consent', 'update', {
        analytics_storage: 'granted'
    })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = url => {
    window.gtag('config', GA_TRACKING_ID, {
        page_path: url
    })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (action, options = {}) => {
    window.gtag('event', action, options)
}
