import styles from './bubble.module.scss'
import { cn } from 'utils/helpers'

export default function Bubble({ content, top, left, align }) {
    return content ? (
        <div
            style={{ left: `${left}px`, top: `${top}px` }}
            className={cn(styles.bubble, styles[align])}
            dangerouslySetInnerHTML={{
                __html: content
            }}
        />
    ) : null
}
