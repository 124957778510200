export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID

// Kai paspaudžiama ant produkto:
// Call this function when a user clicks on a product link. This function uses the event
// callback datalayer variable to handle navigation after the ecommerce data has been sent
// to Google Analytics.
// @param {Object} productObj An object representing a product.
export const productClick = (product, list) => {
    window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: 'productClick',
        ecommerce: {
            click: {
                actionField: { list },
                products: [
                    {
                        ...formProductData(product, true),
                        position: product.__position
                    }
                ]
            }
        },
        eventCallback: function () {}
    })
}

// Kai produktas peržiūrimas:
// Measures product impressions and also tracks a standard
// pageview for the tag configuration.
// Product impressions are sent by pushing an impressions object
// containing one or more impressionFieldObjects.
export const productsListView = (products, list) => {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
        event: 'productImpressions',
        ecommerce: {
            currencyCode: 'EUR',
            impressions: products.map(product => ({
                ...formProductData(product, true),
                position: product.__position,
                list
            }))
        }
    })
}

// When a user sees the product:
export const productView = product => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'ProductView',
        ecommerce: {
            detail: {
                actionField: { list: product.root_category_name }, // Insert product category.
                products: [formProductData(product, true)]
            }
        }
    })
}

// When product is added to cart:
// Measure adding a product to a shopping cart by using an 'add' actionFieldObject
// and a list of productFieldObjects.
export const addToCart = product => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
            currencyCode: 'EUR',
            add: { products: [formProductData(product)] }
        }
    })
}

// When a user checks the cart:
// A function to handle a click on a checkout button. This function uses the eventCallback
// data layer variable to handle navigation after the ecommerce data has been sent to Google Analytics.
export const onCheckoutInitiate = cart => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: { step: 1, option: 'Cart' },
                products: formCartData(cart)
            }
        },
        eventCallback: function () {}
    })
}

// When a user fills in customer information:
// A function to handle a click on a checkout button. This function uses the eventCallback
// data layer variable to handle navigation after the ecommerce data has been sent to Google Analytics.
export const onCheckoutInformation = cart => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: { step: 2, option: 'Contact Information' },
                products: formCartData(cart)
            }
        },
        eventCallback: function () {}
    })
}

// When a user chooses a shipping method:
export const onCheckoutShipping = cart => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: { step: 3, option: 'Shipping' },
                products: formCartData(cart)
            }
        },
        eventCallback: function () {}
    })
}

// When a user chooses a payment method:
export const onCheckoutPayment = cart => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: { step: 4, option: 'Payment' },
                products: formCartData(cart)
            }
        },
        eventCallback: function () {}
    })
}

// When a user makes a purchase:
// Send transaction data with a pageview if available
// when the page loads. Otherwise, use an event when the transaction
// data becomes available.
export const onPurchase = purchase => {
    window.dataLayer = window.dataLayer || []
    //window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
        event: 'purchase',
        ecommerce: { purchase }
    })
    // console.log({
    //     event: 'purchase',
    //     ecommerce: { purchase }
    // })
}

// helpers

const formCartData = (cart, withOutQty = false) => {
    return cart.map(product => formProductData(product, withOutQty))
}

const formProductData = (product, withOutQty = false) => {
    let variant = ''
    if (product.data && product.data.options) {
        variant = Object.keys(product.data.options)
            .filter(key => key != 'personalization')
            .map(key => product.data.options[key])
            .join(', ')
    }

    let price
    if (product.price) {
        price = typeof product.price == 'number' ? product.price.toFixed(2) : product.price
    } else {
        price = typeof product.price_from == 'number' ? product.price_from.toFixed(2) : product.price_from
    }

    const data = {
        name: product.name,
        id: product.code,
        price,
        brand: product.brand_name,
        category: product.category_name,
        variant
    }
    if (!withOutQty) {
        data.quantity = parseInt(product.data.quantity)
    }
    return data
}
