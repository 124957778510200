import Svg from 'components/svg/svg'
import A from 'components/a/a'
import Tooltip from 'components/tooltip/tooltip'
import styles from './info.module.scss'

export default function Info({ text, url }) {
    return (
        <Tooltip content={text}>
            {url ? (
                <A href={url} className={styles.info}>
                    <Svg name="info" />
                </A>
            ) : (
                <span className={styles.info}>
                    <Svg name="info" />
                </span>
            )}
        </Tooltip>
    )
}
