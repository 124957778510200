import { cn } from 'utils/helpers'
import styles from './heading.module.scss'

export default function Heading({ tag, itemProp, size, children, className, center, body }) {
    const Tag = tag
    return (
        <Tag
            itemProp={itemProp}
            className={cn(
                styles.heading,
                styles[`heading${size}`],
                center && styles.center,
                body && styles.body,
                className
            )}
        >
            {children}
        </Tag>
    )
}
