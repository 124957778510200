import Input from 'components/input/input/input'
import Btn from 'components/btn/btn'
import styles from './input.module.scss'

export default function NewsletterInput({ email, onChange, onSubmit }) {
    return (
        <form onSubmit={onSubmit} className={styles.form}>
            <Input
                simple
                type="email"
                name="email"
                value={email}
                required
                className={styles.input}
                onChange={onChange}
                placeholder="El. pašto adresas"
            />
            <Btn type="submit" style="red">
                Prenumeruoti
            </Btn>
        </form>
    )
}

// <button className={styles.submitText} type="submit">
//     Prenumeruoti
// </button>

// import Icon from 'components/icon/icon'
// <Icon label="Prenumeruoti" type="submit" btn="1" className={styles.button} icon="send" />
