import dynamic from 'next/dynamic'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'universal-cookie'
import { useSession } from 'next-auth/client'
import Head from 'next/head'
import styles from './layout.module.scss'
import { useModal } from 'components/modals/provider'
import useEventListener from 'utils/hooks/useEventListener'
import { getRoute } from 'utils/helpers'
import Footer from 'components/footer/footer'

const Header = dynamic(() => import('components/header/header'))
const CookiesCom = dynamic(() => import('components/cookies/cookies'))
const Toast = dynamic(() => import('components/toast/toast'))
const Newsletter = dynamic(() => import('components/newsletter/newsletter'))
const Loader = dynamic(() => import('components/loader/loader'))

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    })
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
        // Add event listener
        window.addEventListener('resize', handleResize)
        // Call handler right away so state gets updated with initial window size
        handleResize()
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty array ensures that effect is only run on mount
    return windowSize
}

export default function Layout(props) {
    const [showCookie, setShowCookie] = useState(true)
    const cookies = new Cookies()
    const cookie = cookies.getAll()
    const [shown, setShown] = useState(false)
    const { showModal } = useModal()
    const size = useWindowSize()

    const profileUrls = getRoute('[profile]')
    const accountUrls = getRoute('[account]')
    const loginUrl = getRoute('login')

    const [authSession, loading] = useSession()
    const router = useRouter()
    const { pathname, asPath } = router
    const showHeader = !pathname.startsWith(profileUrls) && !pathname.startsWith(accountUrls)

    useEffect(() => {
        document.documentElement.style.setProperty('--vw100', document.documentElement.clientWidth + 'px')
    }, [size])

    useEffect(() => {
        const checkObserver = async () => {
            if (typeof window.IntersectionObserver === 'undefined') {
                await import('intersection-observer')
            }
        }
        checkObserver()
    }, [])

    useEventListener('mouseout', e => {
        if (!cookie.shown && !cookie.subscribed) {
            e = e ? e : window ? window.event : null
            const vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
            if (e.clientX >= vpWidth - 50) return
            if (e.clientY >= 50) return
            const from = e.relatedTarget || e.toElement
            if (!from && !shown && !showCookie) {
                setShown(1)
                cookies.set('shown', 1, { path: '/', expires: new Date(Date.now() + 86400000) })
                showModal(Newsletter)
            }
        }
    })

    if (pathname.startsWith(profileUrls) || pathname.startsWith(accountUrls) || pathname === getRoute('onboarding')) {
        if (loading) return null
        if (!loading && !authSession) {
            window.location.href = `${loginUrl}?r=${asPath}`
            return null
            // return (
            //     <Head>
            //         <meta httpEquiv="refresh" content={`0; url=${loginUrl}?r=${asPath}`} />
            //     </Head>
            // )
        }
    }

    const site = 'https://www.lietuviskapreke.lt'
    const canonicalURL = site + asPath.split('?')[0]

    return (
        <>
            <Head>
                <link rel="canonical" href={canonicalURL} />
                <meta property="og:url" content={canonicalURL} />
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />
                <meta property="og:type" content="website" />
                <meta name="verify-neopay" content="f851f55ba1a84e37c4e03439954dcb09" />
                <meta property="fb:app_id" content="387094242361267" />
                <meta name="language" content="lt" />
                <meta name="dc.language" content="lt" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <link rel="shortcut icon" href="/favicon.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="apple-touch-icon" href="/icons/apple-144x144.png" />
                <meta name="msapplication-config" content="/browserconfig.xml" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="theme-color" content="#ffffff" />
            </Head>
            <div className={styles.wrap}>
                {showHeader && <Header />}
                <main>{props.children}</main>
                <Footer />
                <CookiesCom setShowCookie={setShowCookie} showCookie={showCookie} />
                <Toast />
                {/*<ScrollTop />*/}
                <Loader />
            </div>
        </>
    )
}
