import Link from 'next/link'
import Svg from 'components/svg/svg'
import { cn } from 'utils/helpers'
import styles from './icon.module.scss'

export default function Icon({
    href,
    label,
    icon,
    btn,
    className,
    onClick,
    onMouseEnter,
    onMouseLeave,
    children,
    external,
    inactive,
    tag,
    type,
    style
}) {
    const classNames = cn(
        styles.icon,
        btn && styles.btn,
        inactive && styles.inactive,
        className,
        (onClick || href) && styles.action,
        styles[style]
    )
    const svg = icon && <Svg className={styles.image} name={icon} />
    const Tag = tag || 'button'
    type = type || (Tag == 'button' ? 'button' : null)

    const props = {
        onMouseEnter,
        onMouseLeave,
        onClick,
        className: classNames
    }

    if (external) {
        return (
            <a {...props} rel="noreferrer noopener" target="_blank" href={href} aria-label={label}>
                {svg}
                {children}
            </a>
        )
    }

    if (href) {
        return (
            <Link href={href}>
                <a {...props} aria-label={label}>
                    {svg}
                    {children}
                </a>
            </Link>
        )
    }

    if (onClick) {
        return (
            <Tag {...props} type={type} aria-label={label}>
                {svg}
                {children}
            </Tag>
        )
    }

    return (
        <Tag {...props} type={type} aria-label={label}>
            {svg}
            {children}
        </Tag>
    )
}
