const features = [
    {
        name: 'Paveldas',
        slug: 'paveldas',
        description:
            'Produktas, sukurtas saugant tradicinį lietuvišką paveldą. Paveldu laikomas tiek pats produktas (pavyzdžiui, tradicinio audimo skaros), tiek jo gamybos procesas.',
        explain: '',
        url: '/isskirtinumai/paveldas'
    },
    {
        name: 'Inovacija',
        slug: 'inovacija',
        description:
            'Šiai kategorijai priskiriami produktai, kuriuos kuriant buvo pasitelktos technologinės inovacijos, arba kurių dizainas gali būti laikomas inovatyviu.',
        explain: 'technologinė, dizaino etc.',
        url: '/isskirtinumai/inovacija'
    },
    {
        name: 'Saugant gyvūnus',
        slug: 'saugant-gyvunus',
        description:
            'Gyvulinės kilmės produktai, kurie pagaminti atsižvelgiant į gyvūnų gerovę – sudaromos geros sąlygos jų augimui, socializacijai, rūpinamasi jų sveikata.',
        explain: 'gyvulinės kilmės produktams: maistas, vilnos ir pan. gaminiai',
        url: '/isskirtinumai/saugant-gyvunus'
    },
    {
        name: 'Iš vietinių žaliavų',
        slug: 'vietiniu-zaliavu',
        description:
            'Prekės sukurtos pasitelkiant vietinius žaliavų tiekėjus. Produkto kelionė yra trumpa, taip užtikrinant šviežumą (maisto produktams) bei mažesnį CO2 pėdsaką.',
        explain: 'nėra ilgos produkto kelionės, palaikant vietinius žaliavų tiekėjus',
        url: '/isskirtinumai/vietiniu-zaliavu'
    },
    {
        name: 'Sąžiningas atlygis',
        slug: 'saziningas-atlygis',
        description:
            'Atlygis darbuotojams bei tiekėjams mokamas sąžiningai, atsižvelgiant į rinkos situaciją, mokami visi privalomi mokesčiai, atsiskaitoma už viršvalandžius.',
        explain: 'laikantis rinkos kainų, mokant mokesčius nuo pilno atlyginimo, mokant už viršvalandžius',
        url: '/isskirtinumai/saziningas-atlygis'
    },
    {
        name: 'Rankų darbo',
        slug: 'ranku-darbo',
        description:
            'Produktas pagamintas rankomis, yra ne masinės gamybos. Gamybos procesui nereikalingos automatizuotos mašinos, standartizuojančios prekes.',
        explain: '',
        url: '/isskirtinumai/ranku-darbo'
    },
    {
        name: 'Įdarbinant vietines bendruomenes',
        slug: 'idarbinant-vietines-bendruomenes',
        description:
            'Produkto gamybai pasitelkiamos vietinės bendruomenės – įdarbinami jų atstovai arba iš jų perkamos žaliavos, paslaugos.',
        explain: '',
        url: '/isskirtinumai/idarbinant-vietines-bendruomenes'
    },
    {
        name: 'Įdarbinant socialiai jautrias visuomenės grupes',
        slug: 'idarbinant-socialiai-jautrias-visuomenes-grupes',
        description:
            'Produktus gamina socialiai jautrios visuomenės grupės – vieniši, neįgalūs, vyresnio amžiaus asmenys.',
        explain: '',
        url: '/isskirtinumai/idarbinant-socialiai-jautrias-visuomenes-grupes'
    },
    {
        name: 'Ekologiškas',
        slug: 'ekologiskas',
        description:
            'Produktas ir jo sudedamosios dalys yra pilnai ekologiškos ir turi tai patvirtinantį sertifikatą ar kelis.',
        explain: 'sertifikuotas',
        url: '/isskirtinumai/ekologiskas'
    },
    {
        name: 'Bioįvairovės tausojimas',
        slug: 'bioivairoves-tausojimas',
        description:
            'Produktas pagamintas atsižvelgiant į poveikį aplinkai – stengiantis jį kuo labiau sumažinti ar dedant papildomas pastangas siekiant kompensuoti taršos pėdsaką.',
        explain: 'veiksmai aplinkos tausojimui',
        url: '/isskirtinumai/bioivairoves-tausojimas'
    },
    {
        name: 'Natūralus',
        slug: 'naturalus',
        description:
            'Pagamintas iš daugiausiai natūralių ingredientų, nenaudojant (arba minimaliai naudojant) dirbtinių, cheminių priedų.',
        explain: 'pvz., kosmetika iš natūralių žaliavų',
        url: '/isskirtinumai/naturalus'
    },
    {
        name: 'Socialiai atsakingas',
        slug: 'socialiai-atsakingas',
        description:
            'Produktas, už kurį dalis pelno skiriama socialinėms iniciatyvoms, visuomenės edukacijai jautriomis ir svarbiomis temomis, remiamos pažeidžiamos visuomenės grupės.',
        explain: 'remiamos iniciatyvos, edukacija',
        url: '/isskirtinumai/socialiai-atsakingas'
    },
    {
        name: 'Lyčių lygybė',
        slug: 'lyciu-lygybe',
        description:
            'Produkto gamyboje užtikrinama lyčių lygybė – gamintojas įdarbina žmones nepaisant jų lyties, vienodai jiems atlygina už atliktus darbus, o rinkdamasis tiekėjus ar partnerius atsižvelgia į lyčių lygybės principo įgyvendinimą jų įmonėse.',
        explain: '',
        url: '/isskirtinumai/lyciu-lygybe'
    },
    {
        name: 'Tvari gamyba',
        slug: 'tvari-gamyba',
        description:
            'Produkto gamybai naudojami atsinaujinantys energijos šaltiniai (pvz., elektrą generuoja saulės jėgainės), dalis žaliavų yra perdirbama, atliekos perdirbamos vietoje arba parduodamos kaip antrinės žaliavos.',
        explain: 'atsinaujinanti energija, perdirbimas etc.',
        url: '/isskirtinumai/tvari-gamyba'
    },
    {
        name: 'Tvari pakuotė',
        slug: 'tvari-pakuote',
        description:
            'Pakuotė pagaminta iš tvarių medžiagų: nekenkia gamtai, natūraliai suyra. Gali būti tinkama tolesniam naudojimui (t.y., ne vienkartinė) arba pagaminta iš perdirbtų medžiagų.',
        explain: 'perdirbama, iš perdirbtų žaliavų, daugkartinio naudojimo',
        url: '/isskirtinumai/tvari-pakuote'
    },
    {
        name: 'Be atliekų',
        slug: 'be-atlieku',
        description:
            'Gamybos procese nelieka atliekų arba jos yra panaudojamos kitiems produktams sukurti. Likučiai taip pat gali būti perdirbami vietoje arba perduodami perdirbimui užtikrinant antrinį jų panaudojimą.',
        explain: 'gamybos proceso metu nelieka atliekų arba jos panaudojamos, perdirbamos',
        url: '/isskirtinumai/be-atlieku'
    }
]

export default features
