/* eslint-disable @next/next/no-img-element */
import { useState } from 'react'
import Image from 'next/image'
import styles from './image.module.scss'
import { cn } from 'utils/helpers'

export default function Block(props) {
    const [ready, setReady] = useState(!!props.priority)

    if (!props.src || typeof props.src !== 'string') {
        return ''
    }

    const data = props.src.startsWith('data:')
    const loading = !ready && !data
    const handleLoad = event => {
        event.persist()
        setReady(true)
        // if (event.target.srcset) {
        // }
    }

    const handleSource = ({ src }) => {
        return src
    }

    return (
        <Image
            loader={handleSource}
            objectFit="contain"
            {...props}
            width={props.width}
            height={props.height}
            alt={props.alt || ''}
            title={props.alt}
            className={cn(styles.image, loading && styles.loading)}
            onLoad={handleLoad}
            unoptimized
        />
    )
}
