import Wrap from './../wrap'

export default function input(props) {
    const {
        accept,
        onChange,
        onKeyPress,
        required,
        value,
        name,
        readOnly,
        type,
        step,
        min,
        minLength,
        maxLength,
        placeholder
    } = props

    const inputProps = {
        autoCorrect: 'off',
        //autoComplete: 'off',
        //autofill: 'off',
        accept,
        autoCapitalize: 'off',
        spellCheck: 'false',
        onKeyPress,
        step,
        min,
        maxLength: maxLength || 190,
        placeholder,
        onChange,
        required,
        value: (value || value === 0) && type !== 'file' ? value : '',
        name,
        readOnly,
        type
    }

    if (minLength) {
        inputProps.pattern = `.{${minLength},}`
    }

    return (
        <Wrap {...props}>
            <input {...inputProps} />
        </Wrap>
    )
}
