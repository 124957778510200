import lp from './lp'
import algolia from './algolia'
import * as fbq from 'utils/analytics/fpixel'
import * as gtag from 'utils/analytics/gtag'
import * as gtm from 'utils/analytics/gtm'

// after product page opened
export function trackViewAccount({ id }) {
    lp(null, id)
}

// product clicked (not from instant search)
export function trackProductClick(product, skipAlgolia = false) {
    const { id } = product
    if (!skipAlgolia) {
        algolia([id], 'Product Clicked', 'clickedObjectIDs')
    }
    gtm.productClick(product, getListName())
}

// product in search dropdown clicked
export function trackAutocompleteClicked({ id }) {
    algolia([id], 'Product Suggestion Clicked', 'clickedObjectIDs')
}

// products liked
export function trackAddToWishlist({ id }) {
    algolia([id], 'Product Wishlisted', 'convertedObjectIDs', 'convertedObjectIDsAfterSearch')
}

// add to cart
export function trackAddToCart(product) {
    const { id, fb_category, name, data } = product
    const { price, visiblePrice } = data
    const usePrice = visiblePrice ? visiblePrice : price
    algolia([id], 'Add to Cart', 'convertedObjectIDs', 'convertedObjectIDsAfterSearch')
    gtm.addToCart(product)
    fbq.event('AddToCart', {
        content_name: name,
        content_category: fb_category,
        content_ids: [id],
        content_type: 'product',
        value: parseFloat(usePrice),
        currency: 'EUR'
    })
}

// after product page opened
export function trackViewProduct(product) {
    const { id, price, fb_category } = product
    lp(id)
    gtm.productView(product)
    algolia([id], 'Product Viewed', 'viewedObjectIDs')
    fbq.event('ViewContent', {
        content_name: name,
        content_category: fb_category,
        content_ids: [id],
        content_type: 'product',
        value: parseFloat(price),
        currency: 'EUR'
    })
}

// checkout initiated
export function trackInitiateCheckout(cart) {
    const content_ids = cart.map(item => item.code)
    const num_items = cart.reduce((a, b) => a + parseInt(b.data.quantity), 0)
    const value = parseFloat(
        cart
            .reduce((a, b) => {
                const { price_fixed, visiblePrice } = b.data
                const usePrice = price_fixed ? price_fixed : visiblePrice
                return a + parseInt(b.data.quantity) * parseFloat(usePrice)
            }, 0)
            .toFixed(2)
    )
    gtm.onCheckoutInitiate(cart)
    fbq.event('InitiateCheckout', {
        num_items,
        content_ids,
        currency: 'EUR',
        value
    })
}

export function trackFillInformation(cart) {
    gtm.onCheckoutInformation(cart)
}

export function trackSelectShipping(cart) {
    gtm.onCheckoutShipping(cart)
}

export function trackSelectPayment(cart) {
    gtm.onCheckoutPayment(cart)
}

export function trackProductsListView(cart) {
    gtm.productsListView(cart, getListName())
}

// purchase
export function trackPurchase(props) {
    const { analytics_ecommerce, fbq_conversion, analytics_ecommerce_advanced } = props
    gtm.onPurchase(analytics_ecommerce_advanced)
    setTimeout(() => {
        gtag.event('purchase', analytics_ecommerce)
        fbq.event('Purchase', fbq_conversion)
    }, 1500)
    gtag.event('conversion', {
        send_to: 'AW-456388688/yO4wCPvdpe4BENDgz9kB',
        value: analytics_ecommerce.value,
        currency: 'EUR',
        transaction_id: analytics_ecommerce.transaction_id
    })
    algolia(fbq_conversion.content_ids, 'Products Purchased', 'convertedObjectIDs', 'convertedObjectIDsAfterSearch')
}

// list name helper
function getListName() {
    return typeof document != 'undefined'
        ? window.location.pathname === '/'
            ? 'Titulinis'
            : document.title.replace(' | Lietuviška prekė', '')
        : null
}
