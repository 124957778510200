import { useEffect, useState } from 'react'
import { serialize } from 'object-to-formdata'
import { getSession } from 'next-auth/client'
import useAppState from 'utils/state'
import axios from 'axios'
import { stringify } from 'utils/qs'

// Post data
export default function useApi() {
    const appState = useAppState()

    const postWithToast = async (endpoint, data) => {
        const response = await post(endpoint, data)

        if (!response.data) {
            appState.showToast('Įvyko nenumatyta klaida.', 'warning')
        }

        const message = response.data.data ? response.data.data.message : response.data.message
        const error = response.data.data ? response.data.data.error : response.data.error

        if (response.status === 200) {
            appState.showToast(message, 'success')
        } else {
            appState.showToast(error, 'warning')
        }

        return response
    }

    return { postWithToast }
}

// Get data on load
export function useGet(endpoint, query) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const { visitorId } = useAppState()

    useEffect(() => {
        if (!endpoint || !visitorId) return
        const fetchData = async () => {
            setLoading(true)
            const response = await get(endpoint, query)
            if (response.data && response.data.data) {
                setData(response.data.data)
            }
            setLoading(false)
        }

        fetchData()
    }, [endpoint, visitorId])

    return { data, loading }
}

// Get url
export function getUrl(endpoint, query = {}, auth = true) {
    if (typeof window != 'undefined' && auth) {
        if (window.token) {
            query.token = window.token
        }
        if (window.visitorId) {
            query.visitorId = window.visitorId
        }
    }
    const separator = !query || Object.keys(query).length === 0 ? '' : '?'
    const url = `${process.env.NEXT_PUBLIC_API_URL}${endpoint}${separator}${stringify(query)}`
    return url
}

// GET request
export async function get(endpoint, query) {
    const url = getUrl(endpoint, query)

    try {
        return await axios.get(url)
    } catch (err) {
        return err.response
    }
}

// POST request (data can be object for <form> element)
export async function post(endpoint, data) {
    const url = getUrl(endpoint)

    const body =
        data instanceof HTMLElement ? new FormData(data) : serialize(data, { indices: true, booleansAsIntegers: true })

    try {
        return await axios.post(url, body)
    } catch (err) {
        return err.response
    }
}

// list for Next.js
export async function getList(endpoint) {
    try {
        const { data } = await get(endpoint)

        const paths = data.data.map(item => ({
            params: { slug: item.slug }
        }))

        return {
            paths,
            fallback: true
        }
    } catch (e) {
        return {
            paths: [],
            fallback: true
        }
    }
}

// Page data for Next.js
export async function getData(endpoint, serverSide = false, context) {
    const query = await getToken(context)

    try {
        const response = await get(endpoint, query)

        if (!response) {
            return { props: {} }
        }

        const { data, status } = response

        if (status === 404) {
            return { notFound: true }
        }

        const props = status !== 200 || !data.data ? {} : data.data

        if (serverSide) {
            return {
                props
            }
        }

        return {
            props,
            revalidate: 60 * 60
        }
    } catch (err) {
        console.log(err)
        return { notFound: true }
    }
}

export async function getToken(context) {
    if (context) {
        const session = await getSession(context)
        if (session) {
            return { token: session.token }
        }
    }
}
