import { createContext, useState, useContext } from 'react'
import Bubble from './bubble'

const TooltipContext = createContext({
    coordinates: null,
    showTooltip: () => {},
    hideTooltip: () => {}
})

export function TooltipContextProvider({ children }) {
    const [coordinates, setCoordinates] = useState()

    function showTooltip(props) {
        setCoordinates(props)
    }

    function hideTooltip() {
        setCoordinates(null)
    }

    const context = {
        coordinates,
        showTooltip,
        hideTooltip
    }

    return (
        <TooltipContext.Provider value={context}>
            {children}
            <Bubble {...coordinates} />
        </TooltipContext.Provider>
    )
}

export default function useTooltip() {
    return useContext(TooltipContext)
}
