import { useRouter } from 'next/router'
import { cloneElement, useEffect } from 'react'
import useTooltip from './provider'

export default function Tooltip({ children, content, align }) {
    const { showTooltip, hideTooltip, coordinates } = useTooltip()
    const router = useRouter()

    const onMouseEnter = e => {
        const { width, top, left } = e.target.getBoundingClientRect()
        let x
        if (align == 'left') {
            x = left
        } else {
            x = left + width / 2
        }
        showTooltip({ align, left: x, top: top + window.scrollY, content })
    }

    const handleRouteChange = () => {
        if (coordinates && coordinates.content) {
            hideTooltip()
        }
    }

    useEffect(() => {
        router.events.on('routeChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events])

    return cloneElement(children, { onMouseEnter, onMouseLeave: hideTooltip })
}
