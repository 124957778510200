import { useEffect } from 'react'
import { useRouter } from 'next/router'
import * as fbq from 'utils/analytics/fpixel'
import * as gtag from 'utils/analytics/gtag'

const handleRouteChange = url => {
    fbq.pageView()
    gtag.pageView(url)
    if (typeof CFE_destroy !== 'undefined' && window.CFE_ADDED === true) {
        CFE_destroy()
    }
}

export default function PageViews({ children, scriptsLoaded }) {
    const router = useRouter()

    useEffect(() => {
        if (scriptsLoaded) {
            // consent
            const agreeCookies = document.cookie.split('; ').find(row => row.startsWith('agreeCookies='))
            const agree = agreeCookies ? JSON.parse(decodeURIComponent(agreeCookies.split('=')[1])) : []
            if (agree.indexOf('m') < 0) {
                fbq.revoke()
                gtag.revokeAd()
            } else {
                fbq.grant()
                gtag.grantAd()
            }
            if (agree.indexOf('a') < 0) {
                gtag.revokeAnalytics()
            } else {
                gtag.grantAnalytics()
            }

            gtag.init()
            fbq.init()
            fbq.pageView()
            console.log('view')

            router.events.on('routeChangeComplete', handleRouteChange)
        }

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events, scriptsLoaded])

    return children
}
