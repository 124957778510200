import { useState } from 'react'
import Cookies from 'universal-cookie'

import Modal from 'components/newsletter/modal'
import Input from 'components/newsletter/input'
import { useModal } from 'components/modals/provider'

export default function Newsletter({ component }) {
    const Component = component == 'input' ? Input : Modal
    const cookies = new Cookies()
    const [email, setEmail] = useState('')
    const { showMessage } = useModal()

    const subscribeHendler = async e => {
        e.preventDefault()

        const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}subscribe?email=${email}`)
        const result = await res.json()

        cookies.set('subscribed', 1, { path: '/', expires: new Date(Date.now() + 86400000) })

        setEmail('')
        showMessage(result.message)
    }

    return <Component email={email} onChange={e => setEmail(e.target.value)} onSubmit={subscribeHendler} />
}
