import { useState, useEffect } from 'react'
import Svg from 'components/svg/svg'
import Info from 'components/info/info'
import Icon from 'components/icon/icon'
import styles from './wrap.module.scss'
import { cn } from 'utils/helpers'

const notEmpty = value => {
    if (value === null) return false
    if (typeof value === 'object' && value.length === 0) return false
    return value || value === 0
}

export default function Wrap({
    isFocus,
    value,
    readOnly,
    type,
    className,
    label,
    required,
    noAsterisk,
    info,
    infoUrl,
    icon,
    reactSelect,
    onClickIcon,
    content,
    simple,
    children
}) {
    const [hasValue, setHasValue] = useState(notEmpty(value))

    const fileName = type === 'file' && value ? value.name || value.file_name : null

    useEffect(() => {
        if (notEmpty(value)) {
            setHasValue(true)
        } else {
            setHasValue(false)
        }
    }, [value])

    return (
        <div
            className={cn(
                styles.input,
                hasValue && styles.hasValue,
                isFocus && styles.isFocus,
                readOnly && styles.readonly,
                reactSelect && styles.reactSelect,
                simple && styles.simple,
                className
            )}
        >
            <div>
                {children}
                <label>
                    <div>
                        {label && (
                            <div>
                                {label}
                                {!!required && !noAsterisk && ' *'}
                                {info && <Info text={info} url={infoUrl} />}
                            </div>
                        )}
                    </div>
                </label>
                {fileName && <div className={styles.fileName}>{fileName}</div>}
                {type === 'select' && <Svg className={styles.caret} name="down" />}
                {icon && (
                    <Icon
                        btn
                        className={cn(styles.icon, onClickIcon && styles.iconActive)}
                        icon={icon}
                        onClick={onClickIcon}
                    />
                )}
                {content}
            </div>
        </div>
    )
}
