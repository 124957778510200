export const ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID
export const ALGOLIA_API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY

export const ALGOLIA_PRODUCT_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX
export const ALGOLIA_CATEGORY_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_CATEGORY_INDEX
export const ALGOLIA_ACCOUNT_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_ACCOUNT_INDEX

export default function algolia(ids, eventName, actionSimple, actionAfterSearch) {
    //console.log(`Algolia - ${eventName}, ${ids[0]}`)

    const url = new URL(window.location.href)
    const searchParams = url.searchParams
    const queryID = searchParams.get('queryID')

    const objectIDs = ids.map(id => id.toString())

    // if (queryID && actionAfterSearch) {
    //     window.aa(actionAfterSearch, { index: 'products', eventName, queryID, objectIDs })
    // } else {
    //     window.aa(actionSimple, { index: 'products', eventName, objectIDs })
    // }
}
